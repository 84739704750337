import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import Link from "./Link";
import WordBanner from "./WordBanner";

export interface ContentHeaderProps {
  title?: string;
  subtitle?: string;
  topper?: string;
  animationSize?: string;
  wordBanner?: string[];
  background?: IGatsbyImageData;
  banner?: IGatsbyImageData;
  logo?: IGatsbyImageData;
  color?: string;
  colored_banner: boolean;
  backLink?: string;
  backLabel?: string;
}

export default class ContentHeader extends React.Component<ContentHeaderProps> {
  render() {
    return (
      <div className="w-full relative">
        <div className="text-white w-full relative overflow-hidden">
          {/* <div className="relative z-40">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 60 1440 60">
              <path
                fill="#1f0c35"
                fillOpacity={1}
                d="M0,62L120,74C240,88,480,96,720,106.7C960,117,1200,107,1320,101.3L1440,96L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
              ></path>
            </svg>
          </div> */}
          <div
            className={`flex flex-col-reverse md:flex-row items-center justify-center w-full sm:px-8 px-4 relative z-30`}
          >
            <div
              className={`flex flex-col items-center gap-8 pt-8 ${
                this.props.title ? "pb-12" : "pb-4"
              }`}
            >
              {/* <div className="flex flex-row items-center gap-8">
                <img src="/uploads/brandeis_header_logo.svg" className="h-14" />
                <img src="/uploads/das_header_logo.svg" className="h-16" />
              </div> */}
              {this.props.logo && (
                <GatsbyImage
                  image={this.props.logo}
                  alt={this.props.title ?? ""}
                  className="h-36 w-36 sm:h-96 sm:w-96"
                />
              )}
              {this.props.topper && (
                <p
                  className="text-sm sm:text-xl font-bold text-white text-opacity-80 bg-white px-3 sm:px-6 py-2 rounded-xl"
                  style={{
                    color: this.props.color ?? "#1f0c35",
                  }}
                >
                  {this.props.topper}
                </p>
              )}
              <div className="flex flex-col items-center gap-1">
                <h1 className="text-4xl sm:text-7xl md:text-8xl font-black text-opacity-80 text-center max-w-4xl">
                  {this.props.title}
                </h1>
                {this.props.subtitle && (
                  <h2 className="text-2xl sm:text-5xl md:text-6xl font-bold text-white">
                    {this.props.subtitle}
                  </h2>
                )}
              </div>
            </div>
          </div>
          {this.props.banner && (
            <div className="relative z-30 -mb-12 -mr-px -ml-px flex flex-col items-center justify-center">
              <GatsbyImage
                image={this.props.banner}
                alt={this.props.title ?? ""}
              />
            </div>
          )}
          {this.props.colored_banner && this.props.background ? (
            <div
              className="absolute top-0 left-0 w-full h-full z-20"
              style={{
                background: `radial-gradient(circle at 50% 50%, ${this.props.color} 0%, transparent 100%)`,
              }}
            />
          ) : null}
          {this.props.colored_banner && this.props.background ? (
            <div
              className="absolute top-0 left-0 w-full h-full z-20"
              style={{
                background: this.props.color ?? "#1f0c35",
                opacity: 0.7,
              }}
            />
          ) : null}
          {this.props.color && !this.props.background ? (
            <div
              className="absolute top-0 left-0 w-full h-full z-20"
              style={{
                background: this.props.color,
              }}
            />
          ) : null}
          {this.props.background && (
            <div className="absolute top-0 left-0 w-full h-full object-cover z-10">
              <GatsbyImage
                image={this.props.background}
                alt="Banner"
                className="w-full h-full"
              />
            </div>
          )}
          <div className="relative z-40 pb-12 -bottom-6 bg-white -rotate-1">
            {this.props.wordBanner && (
              <WordBanner messages={this.props.wordBanner} color="#1f0c35" />
            )}
          </div>
          {/* <div className="relative z-40">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 50"
              width="auto"
            >
              <path
                className="text-white dark:text-slate-900 fill-current translate-y-0.5 scale-x-105 origin-center"
                fillOpacity={1}
                d="M0,0L1440,50L0,50Z"
              ></path>
            </svg>
          </div> */}
        </div>
        {this.props.backLink && (
          <div
            className="relative z-50 w-full flex items-center justify-center"
            style={{
              marginTop: "calc(-3rem - 1vw)",
            }}
          >
            <div className="w-full flex items-center md:justify-start justify-center max-w-screen-md px-6">
              <Link to={this.props.backLink}>
                <div
                  className="inline-flex flex-row items-center group gap-1 cursor-pointer hover:opacity-100 active:opacity-20 transition-all rounded-2xl px-4 py-2 text-white border-8 border-white"
                  style={{
                    background: this.props.color ?? "#1f0c35",
                  }}
                >
                  <span className="material-symbols-rounded text-2xl group-hover:-translate-x-2 transition-transform">
                    arrow_back
                  </span>
                  <p className="uppercase font-mediu group-hover:-translate-x-1 transition-transform">
                    {this.props.backLabel ?? "Terug"}
                  </p>
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}
